import { APICore } from "./apiCore";
import {StoryCustomChatRequest} from "../../model/StoryCustomChat";

const api = new APICore();

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const STORY_CUSTOMIZATION_CONVERSATION_ENDPOINT = 'api/v1/admin/conversations_with_story_custom';

function storyCustomizationContinuations(storyId: string, data: StoryCustomChatRequest) {
    return api.create(`${baseUrl}/${STORY_CUSTOMIZATION_CONVERSATION_ENDPOINT}/${storyId}/continuations`, data);
}


export {
    storyCustomizationContinuations
};