import { Form, Popconfirm, Switch, Table, Tooltip, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Icon } from "ts-react-feather-icons";
import { ActionType } from "../../constants/actionType";
import { UserRole } from "../../constants/userRole";
import { useUser } from "../../hooks";
import User from "../../model/User";
import CustomPagination from "../CustomPagination";
import {EpisodeModel} from "../../model/Eposode";
import {Link} from "react-router-dom";
import {GalleryModel} from "../../model/Gallery";
import {
    deleteStoryGallery,
    getStoryGalleries,
    getStoryGalleryDetail,
    updateStoryGallery
} from "../../helpers/api/story";
import StoryGalleryDrawer from "./StoryGalleryDrawer";
import VideoPlayer from "../VideoPlayer";

const StoryGalleryTable = ({
    story
}: any) => {

    const [pageSize, setPageSize] = useState<number>(10);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [galleries, setGalleries] = useState<any[]>([]);
    const [galleryDetail, setGalleryDetail] = useState<any>(null);
    const [actionType, setActionType] = useState<string>("");
    const [galleryDrawerVisibility, setGalleryDrawerVisibility] = useState<boolean>(false);

    const currentLoggedInUser = useUser().user as User || null;
    const [api, contextHolder] = notification.useNotification();

    const [form] = Form.useForm();
    const [searchLoading, setSearchLoading] = useState<boolean>(false);


    const showError = (errorMessage: string) => {
        api.error({
            message: `Error notification`,
            description: <span className="text-danger">{errorMessage || 'There\'re some errors occured!'}</span>,
            placement: 'topRight'
        });
    }

    const columns: ColumnsType<GalleryModel> = [
        {
            title: "No.",
            dataIndex: "noNumber"
        },
        {
            title: "Name",
            dataIndex: "name",
            render: (text, record, index) => {
                return (
                    <Link to={`/stories/${story.id}/galleries/${record.id}`}>
                    <span>
                        {record.name}
                    </span>
                    </Link>
                )
            },
        },
        {
            title: 'Image',
            dataIndex: 'image',
            render: (text, record, index) => {
                return (
                    <>
                        {record.imageUrl && record.imageUrl.split('.').pop() === 'mp4' ? (
                            <VideoPlayer url={record.imageUrl} />
                        ) : (
                            <img src={record.imageUrl} alt={record.name} style={{width: '80px'}} />
                        )}
                    </>
                );
            },
        },
        {
            title: "Type",
            dataIndex: "type",

        },
        {
            title: "Created at",
            dataIndex: "createdAt",
            render: (text, record, index) => {
                return <span>{record?.createdAt ? new Date(record?.createdAt).toLocaleString() : ''}</span>
            }
        },
        {
            title: "Created by",
            dataIndex: "createdBy",
        },
        {
            title: "Modified at",
            dataIndex: "modifiedAt",
            render: (text, record, index) => {
                return <span>{record?.modifiedAt ? new Date(record?.modifiedAt).toLocaleString() : ''}</span>
            }
        },
        {
            title: "Modified by",
            dataIndex: "modifiedBy",
        },
        {
            title: "Published?",
            dataIndex: "activated",
            render: (text, record, index) => {
                return (
                    <Popconfirm
                        title={`${(record.status && record.status === "active") ? "Deactivate" : "Activate"} gallery`}
                        description={`Are you sure to ${(record.status && record.status === "active") ? "deactivate" : "activate"} this gallery?`}
                        onConfirm={() => changeActivation(record.id, record.status)}
                        okText="OK"
                        cancelText="Cancel"
                    >
                        <Switch checked={record.status === "active"}
                            disabled={
                                (!currentLoggedInUser?.roles?.includes(UserRole.SUPER_ADMIN)
                                    && !currentLoggedInUser?.roles?.includes(UserRole.ADMIN))
                            }
                        />
                    </Popconfirm>
                )
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 150,
            render: (text, record, index) => {
                return (
                    (currentLoggedInUser?.roles?.includes(UserRole.SUPER_ADMIN) || currentLoggedInUser?.roles?.includes(UserRole.ADMIN)) && (
                        <>
                            <Tooltip title="Update">
                                <span role="button"
                                    onClick={() => triggerUpdate(record.id)}
                                    className="d-inline-block ms-1"
                                >
                                    <Icon name="edit-2" color="#0d6efd" size={16} />
                                </span>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <Popconfirm
                                    title={`Delete episode`}
                                    description={`Are you sure to delete this gallery?`}
                                    onConfirm={() => triggerDelete(record)}
                                    okText="OK"
                                    cancelText="Cancel"
                                >
                                    <span role="button" className="d-inline-block ms-1">
                                        <Icon name="trash-2" color="#dc3545" size={16} />
                                    </span>
                                </Popconfirm>
                            </Tooltip>
                        </>
                    )
                );
            },
        },
    ];

    useEffect(() => {
        if (!story) {
            return;
        }
        search();
    }, [pageIndex]);

    useEffect(() => {
        if (!story) {
            return;
        }
        if (pageIndex === 1) {
            search();
            return;
        }
        setPageIndex(1);
    }, [pageSize]);

    const search = async () => {
        setSearchLoading(true);
        const searchObj: any = {
            pageIndex: pageIndex - 1,
            pageSize,
        };
        try {
            const response = await getStoryGalleries(story.id, searchObj);
            if (response.status === 200) {
                const data: any[] = [];
                response.data.data.forEach((item: any, index: number) => {
                    data.push({
                        ...item,
                        noNumber: (pageIndex - 1) * pageSize + index + 1,
                    });
                });
                setGalleries(data);
                setTotalRecord(response.data.totalRecord);
            }
        } catch (err) {
            console.error(err);
            api.error({
                message: `Error notification`,
                description: <span className="text-danger">{err as string || 'There\'re some errors occured!'}</span>,
                placement: 'topRight'
            });
        }
        setSearchLoading(false);
    }

    const changeActivation = async (galleryId: string, currentStatus: string) => {
        const galleryTemp = await getStoryGalleryDetail(story.id, galleryId);
        setGalleryDetail(galleryTemp.data.data);
        const requestModel: any = {
            status: currentStatus === "active" ? "inactive" : "active"
        }
        const requestBody: any = {
            data: JSON.stringify(requestModel)
        }
        const response = await updateStoryGallery(story.id, galleryId, requestBody);
        if (response.status === 200) {
            api.success({
                message: `Successfull notification`,
                description: (currentStatus === "active" ? 'Deactivate' : 'Activate') + ' gallery successfully!',
                placement: 'topRight'
            });
            search();
        }
    }

    const triggerUpdate = async (galleryId: string) => {
        try {
            if (!galleryId || galleryId?.trim()?.length === 0) {
                return;
            }
            const galleryTemp = await getStoryGalleryDetail(story.id, galleryId);
            setGalleryDetail(galleryTemp.data.data);
            setActionType(ActionType.UPDATE);
            setGalleryDrawerVisibility(true);
        } catch (err) {
            console.error(err);
            showError(err as string);
        }
    }


    const triggerDelete = async (galleryModel: GalleryModel) => {
        try {
            if (!galleryModel || !galleryModel?.id) {
                return;
            }
            if (galleryModel.status === "active") {
                showError("Cannot delete active gallery!");
                return;
            }
            await deleteStoryGallery(story.id, galleryModel.id);
        } catch (err) {
            console.error(err);
            showError(err as string);
        }
        search();
    }

    return (
        <>
            {contextHolder}

            <StoryGalleryDrawer
                story={story}
                drawerVisibility={galleryDrawerVisibility}
                setDrawerVisibility={setGalleryDrawerVisibility}
                storyGalleryDetail={galleryDetail}
                setStoryGalleryDetail={setGalleryDetail}
                actionType={actionType}
                setActionType={setActionType}
                onSuccess={() => {
                    api.success({
                        message: `Successfull notification`,
                        description: (actionType === ActionType.CREATE ? "Create" : "Update") + ' gallery successfully!',
                        placement: 'topRight'
                    });
                    setGalleryDetail(null);
                    setActionType("");
                    search();
                }}
                onFailure={(errorMessage: string) => {
                    showError(errorMessage);
                }}
            />


            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col sm={6} className="mb-2">
                                    <span className="fw-bold lead text-success">GALLERY</span>
                                </Col>
                                <Col sm={6} className="mb-2">
                                    <div className="text-sm-end">
                                        <Button variant="success" className="mb-1 py-1 me-1" onClick={() => {
                                            search();
                                        }}>
                                            <i className="mdi mdi-refresh me-1"></i> Refresh
                                        </Button>
                                        <Button variant="primary" className="mb-1 py-1 me-1" onClick={async () => {
                                            setActionType(ActionType.CREATE);
                                            setGalleryDrawerVisibility(true);
                                        }}>
                                            <i className="mdi mdi-plus-circle me-1"></i> Create
                                        </Button>
                                    </div>
                                </Col>
                            </Row>

                            <Table
                                loading={searchLoading}
                                rowKey={"noNumber"}
                                className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                                dataSource={galleries}
                                columns={columns}
                                pagination={false}
                            />

                            {galleries?.length !== 0 && (
                                <CustomPagination
                                    pageIndex={pageIndex}
                                    pageSize={pageSize}
                                    totalItem={totalRecord}
                                    setPageSize={setPageSize}
                                    setPageIndex={setPageIndex}
                                />
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default StoryGalleryTable;
