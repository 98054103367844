import React, {useState, useEffect} from 'react';
import {Modal, Tooltip, Upload, UploadFile} from "antd";

interface VideoPlayerProps {
    url: string;
}

const VideoPlayer = ({url}: VideoPlayerProps) => {
    // for video
    const [previewVideo, setPreviewVideo] = useState('');
    const [videos, setVideos] = useState<UploadFile[]>([]);

    const [previewVisible, setPreviewVisible] = useState(false);

    const handlePreviewVideo = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }

        setPreviewVideo(file.url || file.preview);
        setPreviewVisible(true);
    };

    useEffect(() => {
        const urlTemp = url.split("/");
        setVideos([{
            uid: "1",
            name: urlTemp[urlTemp.length - 1],
            status: 'done',
            url: url
        }]);
    }, [url]);

    return (
        <>
            <Tooltip title="Click to preview video">
            <video style={{width: '60px', cursor: 'pointer'}} src={url} loop
                     onClick={() => handlePreviewVideo(videos[0])} />
            </Tooltip>

            <Modal open={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                <video style={{width: '80%'}} src={previewVideo} loop autoPlay controls/>
            </Modal>
        </>
    );
};

export default VideoPlayer;