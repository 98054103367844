import {
    Form,
    Table,
    notification, Tooltip,
} from "antd";
import {ColumnsType} from "antd/es/table";
import React, {useEffect, useState} from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import CustomPagination from "../../components/CustomPagination";
import PageTitle from "../../components/PageTitle";
import {useNavigate} from 'react-router-dom';
import {Icon} from "ts-react-feather-icons";
import {
    getStoryCustomizationDetail,
    searchStoryCustomization
} from "../../helpers/api/storyCustomization";
import StoryCustomizationDrawer from "../../components/StoryCustomization/StoryCustomizationDrawer";
import {ActionType} from "../../constants/actionType";
import {searchCharacters} from "../../helpers/api/character";


interface StoryCustomizationModel {
    key: React.Key;
    id: string;
    name: string;
    userId: string;
    plot: string;
    openTurns: string;
    createdAt: Date;

}

const StoryCustomization = () => {
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [storyCustomizations, setStoryCustomizations] = useState<StoryCustomizationModel[]>([]);

    const [storyCustomizationDetail, setStoryCustomizationDetail] = useState<any>(null);
    const [actionType, setActionType] = useState<string>("");
    const [storyCustomizationDrawerVisibility, setStoryCustomizationDrawerVisibility] = useState<boolean>(false);

    const [characters, setCharacters] = useState<any[]>([]);

    const [api, contextHolder] = notification.useNotification();

    const [form] = Form.useForm();
    const formValueObj = Form.useWatch([], form);
    const [searchLoading, setSearchLoading] = useState<boolean>(false);

    const showError = (errorMessage: string) => {
        api.error({
            message: `Error notification`,
            description: <span className="text-danger">{errorMessage || 'There\'re some errors occured!'}</span>,
            placement: 'topRight'
        });
    }


    const columns: ColumnsType<StoryCustomizationModel> = [
        {
            title: "No.",
            dataIndex: "noNumber"
        },
        {
            title: "Name",
            dataIndex: "name"
        },
        {
            title: "User Id",
            dataIndex: "userId"
        },
        {
            title: "Created at",
            dataIndex: "createdAt",
            render: (text, record, index) => {
                return <span>{record?.createdAt ? new Date(record?.createdAt).toLocaleString() : ''}</span>
            }
        },
        {
            title: "Action",
            dataIndex: "id",
            render: (text, record, index) => {
                return (
                    <>
                        <Tooltip title="Update">
                <span role="button"
                      onClick={() => triggerUpdate(record.id)}
                >
                  <Icon name="edit-2" color="#0d6efd" size={16}/>
                </span>
                        </Tooltip>
                        <Tooltip title="Chat">
                  <span role="button" onClick={() => {
                      navigate(`/story_customizations/${record.id}/chat`)
                  }}>
                    <Icon name="message-circle" color="#0d6efd" size={16}/>
                  </span>
                        </Tooltip>
                    </>

                );
            }
        }
    ];

    useEffect(() => {
        search();
    }, [pageIndex]);

    useEffect(() => {
        if (pageIndex === 1) {
            search();
            return;
        }
        setPageIndex(1);
    }, [pageSize]);

    const search = async () => {
        setSearchLoading(true);
        const searchObj: any = {
            pageIndex: pageIndex - 1,
            pageSize,
        };
        try {
            const response = await searchStoryCustomization(searchObj);
            if (response.status === 200) {
                const data: any[] = [];
                response.data.data.forEach((item: any, index: number) => {
                    data.push({
                        ...item,
                        noNumber: (pageIndex - 1) * pageSize + index + 1,
                    });
                });
                setStoryCustomizations(data);
                setTotalRecord(response.data.totalRecord);
            }
        } catch (err) {
            console.error(err);
            api.error({
                message: `Error notification`,
                description: <span className="text-danger">{err as string || 'There\'re some errors occured!'}</span>,
                placement: 'topRight'
            });
        }
        setSearchLoading(false);
    }

    const triggerUpdate = async (storyCustomizationId: string) => {
        try {
            if (!storyCustomizationId || storyCustomizationId?.trim()?.length === 0) {
                return;
            }
            const story = await getStoryCustomizationDetail(storyCustomizationId);
            setStoryCustomizationDetail(story.data.data);
            setActionType(ActionType.UPDATE);
            setStoryCustomizationDrawerVisibility(true);
        } catch (err) {
            console.error(err);
            showError(err as string);
        }
    }

    useEffect(() => {
        loadCharacters();
    }, []);

    const loadCharacters = async (query?: string) => {
        const searchObj: any = {
            pageIndex: 0,
            pageSize: 500,
            status: "active",
            type: "ai_character"
        };
        if (query && query.trim().length > 0) {
            searchObj["name"] = query;
        }
        const characters = await searchCharacters(searchObj);
        setCharacters(characters.data.data);
    }


    return (
        <>
            {contextHolder}
            <PageTitle
                breadCrumbItems={[
                    {label: "Story Customization", path: "/story_customizations"},
                ]}
                title={"Story Customization"}
            />

            <StoryCustomizationDrawer
                drawerVisibility={storyCustomizationDrawerVisibility}
                setDrawerVisibility={setStoryCustomizationDrawerVisibility}
                storyCustomizationDetail={storyCustomizationDetail}
                setStoryCustomizationDetail={setStoryCustomizationDetail}
                characters={characters}
                actionType={actionType}
                setActionType={setActionType}
                onSuccess={() => {
                    api.success({
                        message: `Successfull notification`,
                        description: (actionType === ActionType.CREATE ? "Create" : "Update") + ' story customization successfully!',
                        placement: 'topRight'
                    });
                    setStoryCustomizationDetail(null);
                    setActionType("");
                    search();
                }}
                onFailure={(errorMessage: string) => {
                    showError(errorMessage);
                }}
            />


            {/*<Row>*/}
            {/*    <Col>*/}
            {/*        <Card>*/}
            {/*            <Card.Body>*/}
            {/*                <Form layout="vertical" autoComplete='off' form={form}>*/}
            {/*                    <AntdRow gutter={16}>*/}
            {/*                        <AntdCol span={12}>*/}
            {/*                            <Form.Item*/}
            {/*                                name="searchingDate"*/}
            {/*                                label="Date"*/}
            {/*                            >*/}
            {/*                                <RangePicker className="w-100" showTime />*/}
            {/*                            </Form.Item>*/}
            {/*                        </AntdCol>*/}
            {/*                        <AntdCol span={12}>*/}
            {/*                            <Form.Item*/}
            {/*                                name="issueType"*/}
            {/*                                label="Issue Type"*/}
            {/*                            >*/}
            {/*                                <Select*/}
            {/*                                    placeholder="Select issue type"*/}
            {/*                                    allowClear*/}
            {/*                                >*/}
            {/*                                    <Option value="sexual">Sexual</Option>*/}
            {/*                                    <Option value="repeated">Repeated</Option>*/}
            {/*                                    <Option value="invalidRole">Invalid Role</Option>*/}
            {/*                                </Select>*/}

            {/*                            </Form.Item>*/}
            {/*                        </AntdCol>*/}
            {/*                    </AntdRow>*/}
            {/*                    <AntdRow className="justify-content-center">*/}
            {/*                        <AntdCol>*/}
            {/*                            <AntdButton type="primary" className="me-1" htmlType="submit"*/}
            {/*                                        loading={searchLoading}*/}
            {/*                                        onClick={() => {*/}
            {/*                                            if (pageIndex === 1) {*/}
            {/*                                                search();*/}
            {/*                                                return;*/}
            {/*                                            }*/}
            {/*                                            setPageIndex(1);*/}
            {/*                                        }}*/}
            {/*                            >*/}
            {/*                                Search*/}
            {/*                            </AntdButton>*/}
            {/*                            <AntdButton type="default" loading={searchLoading} onClick={() => {*/}
            {/*                                form.resetFields();*/}
            {/*                                if (pageIndex === 1) {*/}
            {/*                                    search();*/}
            {/*                                    return;*/}
            {/*                                }*/}
            {/*                                setPageIndex(1);*/}
            {/*                            }}>*/}
            {/*                                Reset*/}
            {/*                            </AntdButton>*/}
            {/*                        </AntdCol>*/}
            {/*                    </AntdRow>*/}
            {/*                </Form>*/}
            {/*            </Card.Body>*/}
            {/*        </Card>*/}
            {/*    </Col>*/}
            {/*</Row>*/}

            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col sm={12} className="mb-2">
                                    <div className="text-sm-end">
                                        <Button variant="primary" className="mb-1 py-1 me-1" onClick={async () => {
                                            setActionType(ActionType.CREATE);
                                            setStoryCustomizationDrawerVisibility(true);
                                        }}>
                                            <i className="mdi mdi-plus-circle me-1"></i> Create
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Table
                                loading={searchLoading}
                                rowKey={"noNumber"}
                                className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                                dataSource={storyCustomizations}
                                columns={columns}
                                pagination={false}
                            />

                            {storyCustomizations?.length !== 0 && (
                                <CustomPagination
                                    pageIndex={pageIndex}
                                    pageSize={pageSize}
                                    totalItem={totalRecord}
                                    setPageSize={setPageSize}
                                    setPageIndex={setPageIndex}
                                />
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default StoryCustomization;
