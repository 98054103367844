import { Button, Col, Divider, Drawer, Form, Input, Row, Select, notification } from 'antd';
import { Option } from 'antd/es/mentions';
import React, {useEffect, useState} from 'react';
import {ActionType} from '../../constants/actionType';
import {
    createStoryCustomization,
    genPlotAndOpenTurns,
    updateStoryCustomization
} from "../../helpers/api/storyCustomization";

const storyCustomizationDrawerValidation = {
    name: [
        {required: true, message: 'Name is required'},
        {max: 100, message: 'Name must be less than 100 characters'},
    ],
    plot: [
        {required: true, message: 'Plot is required'},
        {max: 5000, message: 'Plot must be less than 5000 characters'},
    ],
    openTurns: [
        {required: true, message: 'Open turn is required'},
    ],
}

const StoryCustomizationDrawer = ({
                         drawerVisibility,
                         setDrawerVisibility,
                         storyCustomizationDetail,
                         setStoryCustomizationDetail,
                         characters,
                         actionType,
                         setActionType,
                         onSuccess,
                         onFailure
                     }: any) => {

    const [form] = Form.useForm();
    const formValueObj = Form.useWatch([], form);

    const [submittables, setSubmittable] = useState<boolean>(false);
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false);


    const [characterFilters, setCharactersFilters] = useState<any[]>(characters);
    const [charactersSelected, setCharactersSelected] = useState<any[]>([]);


    useEffect(() => {
        if (characters && characters.length > 0) {
            setCharactersFilters([...characters]);
        }
    }, [characters]);
    const filterCharacters = (inputValue: string) => {
        if (inputValue && inputValue.trim().length > 0) {
            const charactersTemp = characters.filter((item: any) =>
                item.name.toLowerCase().includes(inputValue.toLowerCase())
            );
            setCharactersFilters(charactersTemp);
        } else {
            setCharactersFilters([...characters]); // Tạo một bản sao mới của mảng
        }
    }

    const [api, contextHolder] = notification.useNotification();


    useEffect(() => {
        form.validateFields({validateOnly: true})
            .then(
                () => {
                    setSubmittable(true)
                },
                () => {
                    setSubmittable(false)
                },
            );
    }, [formValueObj]);

    useEffect(() => {
        if (formValueObj && formValueObj.characterIds && formValueObj.characterIds.length > 0) {
            const characterIds = formValueObj.characterIds;
            const charactersSelected = characters.filter((item: any) => characterIds.includes(item.id));
            setCharactersSelected([...charactersSelected]);
        }
    }, [formValueObj]);

    useEffect(() => {
        if (actionType === ActionType.CREATE) {
            form.resetFields();
            setSubmittable(false);
        }
    }, [actionType]);

    useEffect(() => {
        if (!storyCustomizationDetail) {

            return;
        }

        form.setFieldsValue({...storyCustomizationDetail});
    }, [storyCustomizationDetail]);

    const onClose = () => {
        form.resetFields();
        setStoryCustomizationDetail(null);
        setSubmittable(false);
        setDrawerVisibility(false);
        setActionType("");
    }

    const saveStoryCustomization = async () => {
        try {
            form.validateFields();

            setLoadingBtn(true);
            let response;
            const requestModel: any = {
                name: formValueObj.name?.trim(),
                plot: formValueObj.plot?.trim(),
                openTurns: formValueObj.openTurns,
                characterIds: formValueObj.characterIds
            }
            if (actionType === ActionType.CREATE) {
                response = await createStoryCustomization({
                    data: JSON.stringify(requestModel)
                });
            } else {
                const requestBody: any = {};
                requestBody["data"] = JSON.stringify(requestModel);
                response = await updateStoryCustomization(storyCustomizationDetail.id, requestBody);
            }

            setLoadingBtn(false);
            if (response?.status === 200) {
                onSuccess();
                onClose();
                return;
            }
        } catch (err) {
            console.log(err);
            onFailure(err);
        }
        setLoadingBtn(false);
    }

    const genByAI = async () => {
        try {
            if (!formValueObj.characterIds || formValueObj.characterIds.length === 0) {
                api.error({
                    message: `Error notification`,
                    description: 'Please select at least one character',
                    placement: 'topRight'
                });
                return;
            }
            let response;
            const requestModel: any = {
                characterIds: formValueObj.characterIds
            }
            response = await genPlotAndOpenTurns({
                data: JSON.stringify(requestModel)
            });
            if (response?.status === 200) {
                form.setFieldsValue({
                    name: response.data.name,
                    plot: response.data.plot,
                    openTurns: response.data.openTurns
                });
                return;
            }
        } catch (err) {
            console.log(err);
            onFailure(err);
        }
    }

    return (
        <Drawer
            title={(actionType === ActionType.UPDATE ? "Update" : "Create") + " a story"}
            className='story-drawer'
            width={750}
            onClose={() => {
                setDrawerVisibility(false)
            }}
            open={drawerVisibility}
            closeIcon={false}
            footer={
                <div className='d-flex justify-content-center'>
                    <Button onClick={saveStoryCustomization} type="primary" className='me-3' disabled={!submittables}
                            loading={loadingBtn}>
                        Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
            }
        >
            {contextHolder}
            <Form layout="vertical" autoComplete='off' form={form}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="characterIds"
                            label="Characters"
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                showSearch
                                onSearch={(value) => {
                                    filterCharacters(value);
                                }}
                                filterOption={false}
                            >
                                {characterFilters && characterFilters.map((item: any) => (
                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16} className='mb-3'>
                    <Col span={12}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={storyCustomizationDrawerValidation.name}
                        >
                            <Input placeholder="Please enter name"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="plot"
                            label="Plot"
                            rules={storyCustomizationDrawerValidation.plot}
                        >
                            <Input.TextArea rows={5} placeholder="Please enter plot"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left">Open Turns</Divider>
                <Form.List name="openTurns">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Row gutter={16} key={key}>
                                    <Col span={12}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'content']}
                                            rules={[{ required: true, message: 'Content is required' }]}
                                        >
                                            <Input.TextArea rows={3} placeholder="Content" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'owner']}
                                            rules={[{ required: true, message: 'Owner is required' }]}
                                        >
                                            <Select
                                                allowClear
                                                placeholder="Owner"
                                            >
                                                {charactersSelected && charactersSelected.map((item: any) => (
                                                    <Option key={item.id} value={item.name}>{item.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Button type="link" onClick={() => remove(name)}>Remove</Button>
                                    </Col>
                                </Row>
                            ))}
                            <Row>
                                <Col span={24}>
                                    <Button type="dashed" onClick={() => add()} block>
                                        Add Open Turn
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}
                </Form.List>
                <Divider orientation="left">Or</Divider>

                <Row gutter={16}>
                    <Col span={24}>
                        <Button type="primary" onClick={() => {
                            genByAI();
                        }}>
                            Generate Plot and Open Turn
                        </Button>
                    </Col>
                </Row>


            </Form>
        </Drawer>
    )

}

export default StoryCustomizationDrawer;
