import { APICore } from "./apiCore";

const api = new APICore();

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const CHARACTER_ENDPOINT = 'api/v1/admin/characters';

function searchCharacters(params: any) {
    return api.get(`${baseUrl}/${CHARACTER_ENDPOINT}`, params);
}


export {
    searchCharacters
}




