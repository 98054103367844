import {Option} from "antd/es/mentions";
import {Select} from "antd";
import React from "react";


interface SelectModelChatProps {
    models: string[];
    handleModelChange: (value: string) => void;
    defaultModel?: string;
}

const SelectModelChat = (props: SelectModelChatProps) => {
    const {models, handleModelChange, defaultModel} = props; // Update this line
    return (
        <Select placeholder="Please choose the model" allowClear onChange={handleModelChange} defaultValue={defaultModel}>
            {models && models.map((item: any) => (
                <Option key={item} value={item}>{item}</Option>
            ))}
        </Select>
    );
}

export default SelectModelChat;