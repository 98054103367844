import { APICore } from "./apiCore";

const api = new APICore();

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const STORY_CUSTOMIZATION_ENDPOINT = 'api/v1/admin/story_customizations';

function searchStoryCustomization(params: any) {
    return api.get(`${baseUrl}/${STORY_CUSTOMIZATION_ENDPOINT}`, params);
}

function getStoryCustomizationDetail(id: string) {
    return api.get(`${baseUrl}/${STORY_CUSTOMIZATION_ENDPOINT}/${id}`, {});
}

function createStoryCustomization(data: any) {
    return api.create(`${baseUrl}/${STORY_CUSTOMIZATION_ENDPOINT}`, data);
}

function updateStoryCustomization(id: string, data: any) {
    return api.update(`${baseUrl}/${STORY_CUSTOMIZATION_ENDPOINT}/${id}`, data);
}

function genPlotAndOpenTurns(data: any) {
    return api.create(`${baseUrl}/${STORY_CUSTOMIZATION_ENDPOINT}/plot_and_open_turns`, data);
}


export {
    searchStoryCustomization,
    getStoryCustomizationDetail,
    createStoryCustomization,
    updateStoryCustomization,
    genPlotAndOpenTurns
}