import {Button as AntdButton, Col as AntdCol, Form, notification, Row as AntdRow} from "antd";
import React, {useEffect, useState} from "react";
import { Card, Col, Row } from "react-bootstrap";
import {useParams} from "react-router-dom";
import PageTitle from "../PageTitle";
import {getStoryCustomizationDetail} from "../../helpers/api/storyCustomization";
import ChatCustomList from "./ChatCustomList";
import SelectModelChat from "./SelectModel";


const StoryCustomizationChat = () => {
    const { storyCustomizationId } = useParams();

    const [storyCustomization, setStoryCustomization] = useState<any>(null);
    const [error, setError] = useState<string>('');

    const [api, contextHolder] = notification.useNotification();

    const [form] = Form.useForm();
    const [searchLoading, setSearchLoading] = useState<boolean>(false);
    const [listModelChat, setListModelChat] = useState<any[]>(["gemini-1.5-flash-latest", "gemini-1.5-flash-8b", "gemini-1.5-pro"]);
    const [model, setModel] = useState<string>('gemini-1.5-flash-8b');

    const showError = (errorMessage: string) => {
        api.error({
            message: `Error notification`,
            description: <span className="text-danger">{errorMessage || 'There\'re some errors occured!'}</span>,
            placement: 'topRight'
        });
    }


    useEffect(() => {
        if (!storyCustomizationId) {
            return;
        }
        getStoryCustomizationDetailById();

    }, [storyCustomizationId]);

    const getStoryCustomizationDetailById = async () => {
        try {
            const res = await getStoryCustomizationDetail(storyCustomizationId as string);
            setStoryCustomization(res.data.data);
        } catch (err) {
            console.error(err);
            showError(err as string);
        }
    }

    useEffect(() => {
        if (error) {
            showError(error);
            setError('')
        }
    }, [error]);


    return (
        <>
            {contextHolder}

            <PageTitle
                breadCrumbItems={[
                    { label: "Story Customization", path: "/story_customization", active: false },
                    { label: "Chat", path: "/story_customization/:storyId/chat", active: true },
                ]}
                title={<span><span className="text-danger">{storyCustomization?.name}</span> Chat</span>}
            />
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Form layout="vertical" autoComplete='off' form={form} name={"Config"}>
                                <AntdRow gutter={16}>
                                    <AntdCol span={12}>
                                        <Form.Item
                                            name="Model"
                                            label="Model"
                                        >
                                            <SelectModelChat models={listModelChat} handleModelChange={(value) => {
                                                setModel(value);
                                            } }
                                                             defaultModel="gemini-1.5-flash-8b" />
                                        </Form.Item>
                                    </AntdCol>
                                </AntdRow>
                                {/*<AntdRow className="justify-content-center">*/}
                                {/*    <AntdCol>*/}
                                {/*        <AntdButton type="primary" className="me-1" htmlType="submit" loading={searchLoading}*/}
                                {/*                    onClick={() => {*/}
                                {/*                        form.validateFields().then(values => {*/}
                                {/*                            setModel(values.Model);*/}
                                {/*                        }).catch(err => {*/}
                                {/*                            console.error(err);*/}
                                {/*                        });*/}
                                {/*                    }}*/}
                                {/*        >*/}
                                {/*            Apply*/}
                                {/*        </AntdButton>*/}
                                {/*    </AntdCol>*/}
                                {/*</AntdRow>*/}
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <ChatCustomList storyCustomization={storyCustomization} model={model}  setError={setError}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default StoryCustomizationChat;
